#page-product{
    color: #404040;
    font-size: 14px;
    background-color: #fafafa;
}
#page-product .brand{
    color: #999;
}
#page-product .quantity-control svg{
    width: 20px;
    height: 20px;
}
#page-product .discount-price{
    font-size: 18px;
    color: #fd5c53;
    font-weight: bold;
}
#page-product .price{
    font-size: 14px;
    text-decoration: line-through;
    color: #404040;
}
#page-product .description{
    color: #777;
}
#page-product .number-button{
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
#page-product .button-bounding-box{
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
}
#page-product .button-bounding-box:nth-last-child(1){
    justify-content: end;
}
#page-product :is(.count,.empty-stock){
    line-height: 30px;
    width: 50px;
    text-align: center;
}
#page-product .empty-stock{
    color: #fd5c53;
}
#page-product .product-category a{
    color: #777;
}
#page-product .box-shadow{
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
@media (min-width: 576px) {
    #page-product .slider-container{
        max-width: 400px;
    }
}