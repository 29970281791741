#page-result .res-content{
    padding: 20px 1px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
}
#page-result .tip{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #404040;
    letter-spacing: 2px;
}
#page-result .tip img{
    width: 20px;
    height: 20px;
    margin-left: 4px;
}
#page-result .data-table{
    font-size: 14px;
    
}
#page-result .data-table .data-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0;
}
#page-result .button-group{
    display: flex;
    flex-direction: column;
}
#page-result .button-group button{
    padding: 10px 88px;
    border-radius: 4px;
    margin-top: 12px;
    font-weight: bold;
    font-size: 14px;
}
#page-result .button-group button.dark{
    background: #222;
    color: white;
    border: solid 1px #222;
}
#page-result .button-group button.light{
    background: white;
    border: solid 1px #d2d2d2;
}