#page-order{
    font-size: 14px;
    color: #404040;
}
#page-order :is(td, button){
    font-size: 14px;
}
#page-order .product-info-table tr td:nth-child(1){
    font-weight: bold;
}
#page-order .product-info .title span.closed img{
    transform: rotate(180deg)
}
#page-order .item{
    color: #404040;
}
#page-order .item .img-container{
    width: 68px;
    height: 68px;
    background-color: rgb(184, 184, 184);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
#page-order .item img{
    width: 100%;
    height: 100%;
}
#page-order .item-list{
    padding-bottom: 12px;
}
#page-order .item-list .button{
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
}