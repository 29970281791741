#page-login #form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
}

@media (max-width: 340px) {
    #page-login #form-container{
        width: 100%;
    }
}