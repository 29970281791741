#page-order-list{
    font-size: 14px;
}
#page-order-list :is(select, option){
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
#page-order-list #query{
    margin-bottom: 20px;
}
#page-order-list .empty-tip img{
 margin-top: 40px;
}
#page-order-list .empty-tip .text{
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #404040;
}
#page-order-list .order .card-head{
    padding-bottom: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#page-order-list .order .card-head .button{
    padding: 2px 20px;
    border-radius: 4px;
}
#page-order-list-date-selector :is(.react-datepicker-wrapper, .react-datepicker__input-container, input){
    width: 124px;
}
#page-order-list-date-selector .react-datepicker-wrapper input{
    padding: 8px 12px;
    font-size: 14px;
    border: solid 1px #d2d2d2;
    border-radius: 4px;
}
#page-order-list-date-selector .date-picker-container img{
    position: relative;
    right: 26px;
    padding-bottom: 4px;
}
#page-order-list-date-selector .start-date{
    padding-left: 12px;
}
#page-order-list-date-selector .dash-container svg{
    position: relative;
    left: -8px;
}
#page-order-list-date-selector .react-datepicker__tab-loop{
    position: fixed;
}
#page-order-list-date-selector .button{
    max-width: 160px;
}