#page-gallery{
    flex: 1;
    overflow: auto;
    background: #fafafa;
}
#page-gallery .carousel-indicators button.active{
    width: 12px;
}
#page-gallery .carousel-indicators button:not(.active){
    width: 3px;
}
#page-gallery .short-description{
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#page-gallery .list-button{
    white-space: nowrap;
    min-width: 90px;
    border-color: #d2d2d2;
    color: #404040;
    background-color: white;
    font-size: 12px;
    font-weight: bold;
}
#page-gallery .list-button:hover{
    border-color: #c2c2c2;
    color: #333333;
}
#page-gallery .next-cate-level{
    border-color: #a8a8a8;
    border-width: 1px;
    border-style: solid;
    color: #575757;
    text-decoration: none;
    margin-right: 5px;
    white-space: nowrap;
    border-radius: 5px;
}
#page-gallery  .product-category a{
    color: #777;
}
#page-gallery .breadcrumb{
    margin: 0;
}
#page-gallery .banner-container{
    margin: 16px;
}
#page-gallery .banner-container .carousel-inner{
    border-radius: 8px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}
#page-gallery .news-container{
    padding: 0 32px;
    overflow: hidden;
    text-overflow: ellipsis;
}
#page-gallery .news-container .main{
    font-size: 14px;
    font-weight: bold;
    color: #404040;
    padding-right: 8px;
    white-space: nowrap;
    line-height: 14px;
}
#page-gallery .news-container .slave{
    padding-left: 8px;
    font-size: 12px;
    line-height: 12px;
    color: #404040;
    white-space: nowrap;
}
#page-gallery .news-container .splitter{
    width: 1px;
    height: 12px;
    background-color: #d2d2d2;
    padding-bottom: 1px;
}
#page-gallery .product-list{
    margin: 0 16px 16px 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
#page-gallery .product-item{
    padding-bottom: 16px;
    list-style-type: none;
}
#page-gallery .product-item:nth-last-child(1){
    padding-bottom: 0;
}
#page-gallery .product-item img, .page-gallery-product-info-pop img{
    width: 128px;
    height: 128px;
    border-radius: 4px;
}
#page-gallery .product-item .info{
    margin-left: 16px;
}
#page-gallery .product-item .price{
    font-size: 14px;
    color: #404040;
    text-decoration: line-through;
}
#page-gallery .product-item .discount_price, .page-gallery-product-info-pop .discount_price{
    color: #fd5c53;
    font-size: 18px !important;
    font-weight: bold;
    text-decoration: none;
}
#page-gallery .product-item .name, .page-gallery-product-info-pop .name{
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404040;
    margin-bottom: 4px;
}
#page-gallery .product-item .name{
    font-size: 14px;
}
.page-gallery-product-info-pop .price{
    font-size: 12px;
}
.page-gallery-product-info-pop .name{
    font-weight: 16PX;
}
#page-gallery .product-item .brand, .page-gallery-product-info-pop .brand{  
    color: #999;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
}
.page-gallery-product-info-pop{
    margin: 16px;
    background-color: white;
    border-radius: 8px;
}
.page-gallery-product-info-pop .underline{
    padding-bottom: 16px;
    border-bottom: #ececec solid 1px;
}
.page-gallery-product-info-pop .foot{
    padding-top: 16px;
}
.page-gallery-product-info-pop .foot .label{
    font-size: 14px;
    font-weight: bold;
    color: #404040;
}
.page-gallery-product-info-pop .foot .number-button svg{
    width: 20px;
    height: 20px;
}
.page-gallery-product-info-pop .foot .number-button:nth-child(1){
    padding-right: 10px;
    padding-left: 10px;
}
.page-gallery-product-info-pop .foot .number-button:nth-child(3){
    padding-left: 10px;
}
.page-gallery-product-info-pop .foot .count{
    width: 30px;
    font-size: 14px;
    text-align: center;
}
.page-gallery-product-info-pop .foot .button-group{
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
}
.page-gallery-product-info-pop .foot .check-button{
    min-width: 150px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.page-gallery-product-info-pop .foot .check-button.light{
    background: white;
    border: solid 1px #d2d2d2;
    color: black;
}
.page-gallery-product-info-pop .foot .check-button.dark{
    background: #222;
    border: solid 1px #222;
    color: white;
}
#search-filter-pannel{
    font-size: 14px;
}
#search-filter-pannel *{
    outline: none !important;
}
#search-filter-pannel .selector-label{
    margin: 12px 0 4px 0;
}
#search-filter-pannel .selector-label img{
    margin-right: 4px;
}
#search-filter-pannel :is(input,select){
    min-width: 0;
    border: #d2d2d2 solid 1px;
    border-radius: 4px;
    padding: 8px 12px;
    color: #404040;
    background-color: #fff;
}
#search-filter-pannel input[type="number"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-appearance: textfield;
    background: url('../../assets/icon-button-amount-normal.svg');
    background-repeat: no-repeat;
    background-position: right 10px top 12px;
}
#search-filter-pannel input::-webkit-outer-spin-button,
#search-filter-pannel input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#search-filter-pannel input::placeholder{
    color: #d2d2d2;
}
#search-filter-pannel .price_range_container{
    margin-top: 4px;
    color: #d2d2d2;
}
#search-filter-pannel .price_range_container input{
    border-color: #d2d2d2;
}
#search-filter-pannel input:focus{
    border-color: #222;
    color: #222;
}
#search-filter-pannel .price_range_container:has(input:focus) span{
    color: #222;
}
@media (max-width: 768px) {
    #search_bar_side{
        display: none !important;
    }
}
@media (min-width: 768px) {
    #search_bar_sub{
        display: none !important;
    }
}