#page-cart{
    background-color: #fafafa;
}
.cart-page-modal-container .text{
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #404040;
}
.cart-page-modal-container .button-group{
    margin-top: 36px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
:is(#page-cart-remove-tip-container, #page-stock-tip-container) .button:nth-child(1){
    margin-right: 11px;
}
.cart-page-modal-container .button{
    max-width: 150px;
    min-width: 120px;
    height: 40px;
    padding: 10px 38px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.cart-page-modal-container .button.light{
    border: solid 1px #d2d2d2;
    color: #404040;
    background-color: white;
}
.cart-page-modal-container .button.dark{
    border: solid 1px #222;
    color: white;
    background: #222;
}
#page-cart-total-tip-container .button{
    max-width: 300px;
}
#page-cart .list{
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
}
#page-cart .item{
    margin: 0;
    padding: 16px;
}
#page-cart .item .close-button-container svg{
    fill: #999
}
#page-cart .item:not(:nth-last-child(1)){
    border-bottom: #ececec solid 1px;
}
#page-cart .item .img-container{
    width: 88px;
    height: 88px;
    background-color: rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
#page-cart .item img{
    width: 88px;
    height: 88px;
    border-radius: 4px;
}
#page-cart .item-body{
    margin-left: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
#page-cart .item-body .title{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
#page-cart .item-body .title .close-button-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-left: 16px;
}
#page-cart .item-body .title, #page-cart .item-body .title a{
    font-size: 14px;
    font-weight: 500;
    color: #404040;
}
#page-cart .item-body .price-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
#page-cart .item-body .price-discount{
    font-size: 16px;
    font-weight: 600;
    color: #fd5c53;
}
#page-cart .item-body .price{
    font-size: 12px;
    font-weight: normal;
    color: #404040;
    margin-left: 4px;
    padding-bottom: 1px;
    text-decoration: line-through;
}
#page-cart .item-body .count-selector{
    display: flex;
    align-items: center;

}
#page-cart .item-body .count-selector .count{
    width: 32px;
    text-align: center;
}
#page-cart .item-body .count-selector .button{
    background-color: white;
}
#page-cart .item-body .count-selector .button:nth-child(1){
    padding-right: 14px;
}
#page-cart .item-body .count-selector .button:nth-child(3){
    padding-left: 14px;
}
#page-cart .item-body .sub{  
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: #404040;
}
#page-cart .item.invalid .img-container img{
    filter: opacity(.5);
}
#page-cart .item.invalid :is(.title, .price, .sub, a){
    color: rgb(182, 182, 182);
}
#page-cart .item.invalid .price-discount{
    color: #ff9892;
}
#page-cart .item.invalid .count{
    color: #ff9892;
    font-size: 14px;
    width: 50px;
}
#page-cart .item.invalid .item-body .count-selector .button:nth-child(1){
    padding-right: 4px;
}
#page-cart .item.invalid .item-body .count-selector .button:nth-child(3){
    padding-left: 4px;
}
#page-cart .item.invalid :is(.close-button-container svg, .count-selector .button svg){
    fill: rgb(219, 219, 219);
}
#page-cart .price{
    font-weight: bold;
}
#page-cart .button-bounding-box{
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
}
#page-cart .button-bounding-box:nth-last-child(1){
    justify-content: end;
}
#page-cart .next-step{
    background-color: #0089ff;
    color: white;
    font-weight: bold;
}
#page-cart .coupon{
    background: #def0f7;
    color: #4a6389;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 12px;
    border-left-width: 20px;
    border-color: #88a3c7;
    border-left-style: solid;
}
#page-cart .coupon-container{
    margin: 16px 0;
    font-size: 14px;
}
#page-cart .coupon-container input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
#page-cart .coupon-container input::placeholder{
    font-size: 14px;
}
#page-cart .coupon-button{
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background: #222222;
    color: white;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
#page-cart .payment-block :is(input, input::placeholder, select, label, button){
    font-size: 14px;
}
#page-cart .payment-block input::placeholder{
    color: #b1b1b1;
}
#page-cart .payment-block .title{
    padding: 2px 2px 12px 0;
    font-size: 14px;
    font-weight: bold;
    color: #404040;
}
#page-cart .payment-block .title .requirenote{
    margin-left: 2px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #fd5c53;
}
#page-cart .remove-coupon-button{
    padding: 4px 4px 4px 20px;

}
#page-cart .remove-coupon-button svg{
    margin: 0;
    padding: 0;
}
#page-cart .summary-container{
    padding: 12px 16px;
    margin-bottom: 16px;
}
#page-cart .summary-container .title{
    font-size: 14px;
    font-weight: bold;
    color: #404040;
    padding-bottom: 12px;
    margin-bottom: 12px;
}
#page-cart .summary-container .content-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#page-cart .summary-container .content{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #404040;
}
#page-cart .summary-container .content tbody{
    padding-bottom: 11px;
}
#page-cart .summary-container .content .sum{
    font-size: 14px;
    font-weight: bold;
    color: #404040;
}
#page-cart .foot{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
}
#page-cart .foot .sum{
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    color: #222;
}
#page-cart .foot .sum-label{
    font-size: 16px;
    font-weight: 600;
    color: #222;
    width: 165px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
#page-cart .foot .go-checkout-button>span{
    width: 165px;
    height: 40px;
    padding: 10px 45px 10px 44px;
    border-radius: 4px;
    background-color: #222;
    font-size: 16px;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
#page-cart .tip-img-empty{
    margin-top: 53px;
}
#page-cart .cart-tip-empty{
    font-size: 14px;
    font-weight: bold;
    color: #404040;
}
#page-cart .go-home-button{
    padding: 8px 41px 8px 42px;
    border-radius: 4px;
    background-color: #222;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-top: 64px;
}