
body {
  font-family: Microsoft JhengHei, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body,#root,.App{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.App{
  display: flex;
  flex-direction: column;
}
a{
  text-decoration: none !important;
}
input, select{
  -webkit-appearance: none;
  -moz-appearance: none;
}
select{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./assets/icon-button-arrow-down-normal.svg') !important;
  background-repeat: no-repeat;
  background-position: right 10px top 12px;
}
.form-check-input{
  border-radius: 0 !important;
  border: none !important;
  background-color: white !important;
}
.form-check-input:not(:checked){
  background-image: url('./assets/icon-button-check-default.svg') !important;
}
.form-check-input:checked{
  background-image: url('./assets/icon-button-check-success.svg') !important;
}
.form-check-input[type="radio"]:checked{
  background-image: url('./assets/icon-button-check-single.svg') !important;
}
.no-arrow{
  background-image: none !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid, select:invalid{
  border-color: #fd5c53 !important;
}
.invalid-feedback{
  color: #fd5c53;
}
.block{
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.underline{
  border-bottom: solid 1px #ececec;
}
.mono-button{
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}
.mono-button:disabled{
  border-color: #d2d2d2 !important;
  background-color: #d2d2d2 !important;
  color: #fff !important;
}
.mono-button.dark{
  background: #222;
  color: white !important;
  border: solid 1px #222;
}
.mono-button.light{
  background: white;
  border: solid 1px #d2d2d2;
  color: #404040 !important;
}
.mono-button.light *{
  color: #404040 !important;
}
.round-toast{
  pointer-events: none;
}
.fs-16{
  font-size: 16px;
}
.fs-14{
  font-size: 14px;
}
.fs-12{
  font-size: 12px;
}
.m-20{
  margin: 20px;
}
.p-20{
  padding: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.mt-20{
  margin-top: 20px;
}
.pb-20{
  padding-bottom: 20px;
}
.pt-20{
  padding-top: 20px;
}
.mx-20{
  margin-left: 20px;
  margin-right: 20px;
}
.my-20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.ms-20{
  margin-left: 20px;
}
.me-20{
  margin-right: 20px;
}
.px-20{
  padding-left: 20px;
  padding-right: 20px;
}
.py-20{
  padding-top: 20px;
  padding-bottom: 20px;
}
.ps-20{
  padding-left: 20px;
}
.pe-20{
  padding-right: 20px;
}
.m-16{
  margin: 16px;
}
.p-16{
  padding: 16px;
}
.mb-16{
  margin-bottom: 16px;
}
.mt-16{
  margin-top: 16px;
}
.ms-16{
  margin-left: 16px;
}
.me-16{
  margin-right: 16px;
}
.pb-16{
  padding-bottom: 16px;
}
.pt-16{
  padding-top: 16px;
}
.ps-16{
  padding-left: 16px;
}
.pe-16{
  padding-right: 16px;
}
.mx-16{
  margin-left: 16px;
  margin-right: 16px;
}
.my-16{
  margin-top: 16px;
  margin-bottom: 16px;
}
.px-16{
  padding-left: 16px;
  padding-right: 16px;
}
.py-16{
  padding-top: 16px;
  padding-bottom: 16px;
}
.m-14{
  margin: 14px;
}
.p-14{
  padding: 14px;
}
.mb-14{
  margin-bottom: 14px;
}
.mt-14{
  margin-top: 14px;
}
.ms-14{
  margin-left: 14px;
}
.me-14{
  margin-right: 14px;
}
.pb-14{
  padding-bottom: 14px;
}
.pt-14{
  padding-top: 14px;
}
.ps-14{
  padding-left: 14px;
}
.pe-14{
  padding-right: 14px;
}
.mx-14{
  margin-left: 14px;
  margin-right: 14px;
}
.my-14{
  margin-top: 14px;
  margin-bottom: 14px;
}
.px-14{
  padding-left: 14px;
  padding-right: 14px;
}
.py-14{
  padding-top: 14px;
  padding-bottom: 14px;
}
.m-12{
  margin: 12px;
}
.p-12{
  padding: 12px;
}
.mb-12{
  margin-bottom: 12px;
}
.mt-12{
  margin-top: 12px;
}
.pb-12{
  padding-bottom: 12px;
}
.pt-12{
  padding-top: 12px;
}
.ps-12{
  padding-left: 12px;
}
.pe-12{
  padding-right: 12px;
}
.mx-12{
  margin-left: 12px;
  margin-right: 162x;
}
.my-12{
  margin-top: 12px;
  margin-bottom: 12px;
}
.px-12{
  padding-left: 12px;
  padding-right: 12px;
}
.py-12{
  padding-top: 12px;
  padding-bottom: 12px;
}
.m-8{
  margin: 8px;
}
.p-8{
  padding: 8px;
}
.mb-8{
  margin-bottom: 8px;
}
.mt-8{
  margin-top: 8px;
}
.ms-8{
  margin-left: 8px;
}
.me-8{
  margin-right: 8px;
}
.pb-8{
  padding-bottom: 8px;
}
.pt-8{
  padding-top: 8px;
}
.ps-8{
  padding-left: 8px;
}
.pe-8{
  padding-right: 8px;
}
.mx-8{
  margin-left: 8px;
  margin-right: 8px;
}
.my-8{
  margin-top: 8px;
  margin-bottom: 8px;
}
.px-8{
  padding-left: 8px;
  padding-right: 8px;
}
.py-8{
  padding-top: 8px;
  padding-bottom: 8px;
}

.m-6{
  margin: 6px;
}
.p-6{
  padding: 6px;
}
.mb-6{
  margin-bottom: 6px;
}
.mt-6{
  margin-top: 6px;
}
.ms-6{
  margin-left: 6px;
}
.me-6{
  margin-right: 6px;
}
.pb-6{
  padding-bottom: 6px;
}
.pt-6{
  padding-top: 6px;
}
.ps-6{
  padding-left: 6px;
}
.pe-6{
  padding-right: 6px;
}
.mx-6{
  margin-left: 6px;
  margin-right: 6px;
}
.my-6{
  margin-top: 6px;
  margin-bottom: 6px;
}
.px-6{
  padding-left: 6px;
  padding-right: 6px;
}
.py-6{
  padding-top: 6px;
  padding-bottom: 6px;
}
@media (min-width: 1320px){
  .page-content{
    width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 768px) {
  .hide-if-sm{
      display: none !important;
  }
}
@media (min-width: 768px) {
  .hide-if-wd{
      display: none !important;
  }
}