.page-content{
    flex: 1;
    overflow: auto;
}
#footer .footer-button{
    color: #7a899e;
}
#footer .footer-button .badge{
    font-size: 12px;
    bottom: 20px;
    position: fixed;
}
.navbar-menu>a>span{
    padding: 8px 32px;
}
.dropdown-menu{
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: solid 1px #d2d2d2;
    background-color: #fafafa;
    padding: 0;
    border-radius: 0;
}
.dropdown-menu a li{
    font-weight: normal;
}
.dropdown-toggle.nav-link.sub-nav a{
    padding: 8px;
}
.dropdown-item{
    padding: 0 16px 0 16px;
    height: 40px;
    display: flex;
    align-items: center;
}
.basic-nav-item .dropdown-item.active{
    background-color: #222222 !important;
    font-weight: bold;
}
a:last-child .dropdown-item{
    padding-bottom: 0;
}
.dropdown>a>span>span{
    color: #404040;
}
.navbar{
    padding: 0;
}
.navbar button {
    outline: none !important;
    box-shadow: none !important;
}
.navbar-toggler-icon{
    width: 20px;
    height: 20px;
    fill: black;
}
.navbar-toggler{
    border: none;
    padding: 12px 16px;
}
header{
    /* height: 48px; */
}
header .container{
    padding: 0;
}
.nav-tool-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-tool-bar .nav-tool-icon{
    color: black;
    padding: 0 8px;
    height: 20px;
}
.nav-tool-bar .nav-tool-icon:nth-last-child(1){
    padding-right: 16px;
}
.nav-tool-bar .nav-tool-icon:nth-child(1){
    padding-left: 16px;
}
.nav-tool-bar .nav-tool-icon .badge{
    -webkit-text-size-adjust: none;
    font-family: Courier, monospace;
    font-size: 14px;
    font-weight: lighter;
    color: rgb(255, 255, 255);
    border: white solid 1px;
    border-radius: 50%;
    background-color: #fd5c53;
    text-align: center;
    width: 20px;
    height: 20px;
    transform: scale(.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -26px;
    right: -5px;
}
.nav-tool-bar .nav-tool-icon .badge>span{
    margin: auto;
    line-height: 13px;
    min-height: 0;
    top: -2px;
    font-weight: bold;
    position: relative;
}
.navbar-toggler{
    outline: none;
}
#basic-navbar-nav .nav-link{
    font-weight: bold;
}
#basic-navbar-nav .dropdown-toggle.nav-link::after{
    display: none;
}
.basic-nav-item{
    padding: 0px 32px;
    letter-spacing: 2px;
    color: #404040;
    font-weight: bold;
}
.basic-nav-item .basic-dropdown-item{
    background-image: url('./assets/icon-button-arrow-down-normal.svg');
    background-repeat: no-repeat;
    background-position: bottom 6px right 0px;
}
.basic-dropdown-item{
    color: #404040 !important;
}
.basic-nav-item.show .basic-dropdown-item{
    background-image: url('./assets/icon-button-arrow-up-normal.svg');
}
.navbar-menu{
    padding: 12px 0;
}
.brand{
    display: none !important;
}
.payment-info .label{
    margin-bottom: 4px;
}
.payment-info .strong{
    color: #222222;
    font-size: 16px;
    font-weight: bold;
}
.payment-info .account .copy-button{
    cursor: pointer;
}
.payment-info .account-code{
    margin-right: 8px;
}
.payment-info .expiration strong{
    margin-bottom: 4px;
}
.payment-info .expiration .warning{
    color: #fd5c53;
}
.payment-info .tips{
    color: #999;
    font-size: 12px;
}
.payment-info .tips li{
    padding-bottom: 8px;
}
.payment-info .bank-info img{
    max-width: 20px;
    max-height: 20px;
}
.product-picture{
    object-fit: cover;
}
#search-pannel{
    position: fixed;
    z-index: 100;
    top: 0;
    background-color: rgba(0, 0, 0, 0.144);
    width: 100%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    height: 100%;
    overflow-y: auto;
}
#search-pannel *{
    outline: none;
}
#search-pannel #search-bar{
    padding-top: 4px;
    padding-bottom: 4px;
}
#search-pannel #search-bar input{
    border-radius: 4px 0 0 4px !important;
    border: solid 1px #222;
    border-right: none;
    font-size: 14px;
    height: 36px;
}
#search-pannel #remove-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-top: solid 1px #222;
    border-bottom: solid 1px #222;
}
#search-pannel .search-icon-container{
    background-color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-width: 0;
    cursor: pointer;
}
#search-pannel .search-history-item{
    padding: 6px 16px 6px 16px;
    font-size: 14px;
}
#search-pannel .search-candidate-list{
    padding-top: 6px;
    padding-bottom: 6px;
}
#search-pannel .candidate-item{
    font-size: 14px;
}
#search-pannel .candidate-item strong{
    font-weight: bold;
}
.discount-info{
    color: #fd5c53;
    font-size: 11px;
}
.discount-info .rate{
    padding: 0 5px 0 6px;
    border-radius: 2px;
    color: white;
    background-color: #fd5c53;
    margin-right: 4px;
}
.round-toast{
    padding: 6px 16px;
    background-color: #222;
    color: white;
    font-size: 14px;
    top: calc(100vh - 88px);
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 99999;
    border-radius: 17px;
}
.default-button{
    min-width: 150px;
    padding: 4px 10px;
    border-radius: 4px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    border-style: solid;
}
.default-button.light{
    background: white;
    border: solid 1px #d2d2d2;
    color: black;
}
.default-button.dark{
    background: #222;
    border: solid 1px #222;
    color: white;
}
@media (max-width: 992px) {
    #basic-navbar-nav{
        position: fixed;
        top: 48px;
        height: calc(100% - 48px);
        z-index: 99998;
        width: 100%;
        max-width: 720px;
        background-color: rgb(64, 64, 64, .35);
    }
    #basic-navbar-nav .navbar-menu{
        max-height: 100%;
        overflow-y: auto;
        background-color: #fafafa;
        width: 100%;
        box-shadow: inset 0 2px 10px -7px rgba(0,0,0,0.5);
    }
}
@media (min-width: 720px){
    .basic-dropdown-item{
        padding-right: 18px;
    }
    #basic-navbar-nav{
        background: none;
    }

}