#page-bill-settings, #page-bill-settings :is(input, input::placeholder, select, option){
    font-size: 14px;
}
#page-bill-settings .greetings img{
    width: 20px;
    height: 20px;
}
#page-bill-settings .greetings .text{
    margin-left: 8px;
    margin-right: 8px;
}
#page-bill-settings .greetings .customer_name{
    font-weight: bold;
}
#page-bill-settings button:is(.button-cancel, .button-save) span{
    min-width: 100px;
    display: block;
}
#page-bill-settings button.button-cancel{
    margin-right: 5px;
}
#page-bill-settings button.button-save{
    margin-left: 5px;
}