#page-account h1{
    /* border-bottom: rgba(0, 0, 0, 0.103) solid 1px; */
    margin: 0;
}
#page-account .nav-item button:not(.active){
    color: rgb(172, 172, 172);
}

#page-account .head{
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
#page-account .head .tab{
    padding: 12px 6px 10px 6px;
    border-bottom: transparent solid 2px;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #999;
}
#page-account .head .tab.active{
    border-color: #222;
    color: #222;
}

@media (max-width: 768px) {
    #page-account #entry_tab{
        display: none !important;
    }
}
@media (min-width: 768px) {
    #page-account #entry_flat{
        display: none !important;
    }
}