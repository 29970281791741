
#page-checkout .underline{
    border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
}
#page-checkout .block .title{
    padding: 2px 2px 12px 0;
    font-size: 14px;
    font-weight: bold;
    color: #404040;
}
#page-checkout .block .title .requirenote{
    margin-left: 2px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #fd5c53;
}
#page-checkout .block .title .requirenote::after{
    content: '*';
}
#page-checkout :is(input, input::placeholder, select, label, button){
    font-size: 14px;
}
#page-checkout input::placeholder{
    color: #b1b1b1;
}
#page-checkout .submit_button{
    flex: 1;
    padding: 10px 88px;
    border-radius: 4px;
    background-color: #222;
    color: white;
}