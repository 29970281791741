
#cart-progress-chart{
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;
}
#cart-progress-chart .step-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 256px;
    height: 100%;
    position: relative;
    left: -114px;
    z-index: 99;
}
#cart-progress-chart .step{
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -9px;
}
#cart-progress-chart .step .circle{
    width: 30px;
    height: 30px;
    padding: 5px 12px;
    background-color: #d2d2d2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
#cart-progress-chart .step label{
    color: #404040;
    font-size: 12px;
    text-align: center;
    width: 88px;
    padding-top: 8px;
}
#cart-progress-chart .step.active .circle{
    background-color: black;
}
#cart-progress-chart .progress-bar{
    height: 1px;
    min-height: 1px;
    width: 232px;
    min-width: 232px;
    background-color: #d2d2d2;
    border: none;
    z-index: 1;
    left: 130px;
    top: -13px;
    position: relative;
}